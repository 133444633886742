import React from "react";
import { Container } from "react-bootstrap";

const PageBanner = (props) => {
  // console.log(props);
  const webbanner = props?.banner?.DesktopBanner?.data?.attributes?.url;
  const mobbanner = props?.banner?.MobileBanner?.data?.attributes?.url;

  const BannerText = () => {
    return (
      <>
        <div className="banner-wrap">
          <div className="d-flex align-items-center justify-content-center">
            <h1 className="banner-title text-white">
              {props?.banner?.BannerTitle}
            </h1>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <section
        className="inner-banner d-none d-md-flex"
        style={{
          backgroundImage: `url(
            ${process.env.NEXT_PUBLIC_BACKEND_API_URL + webbanner}
          )`,
        }}
      >
        <div className="inner-overlay">
          <Container>
            <BannerText />
          </Container>
        </div>
      </section>
      <section
        className="inner-banner d-flex d-md-none"
        style={{
          backgroundImage: `url(
              ${process.env.NEXT_PUBLIC_BACKEND_API_URL + mobbanner}
            )`,
        }}
      >
        <div className="inner-overlay">
          <Container>
            <BannerText />
          </Container>
        </div>
      </section>
    </>
  );
};

export default PageBanner;
